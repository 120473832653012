<template>
    <v-app>
        <v-app-bar
            app
            color="grey darken-4"
            dark>
            Heatmap
            <v-spacer></v-spacer>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        //
    }),
};
</script>
<style>
.row-centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>

const util = {
    thres_to_color(color = '#0000ff', thr, val) {
        const ratio = val / thr;
        const c = color.replace('#', '');
        const r = c[0] + c[1];
        const g = c[2] + c[3];
        const b = c[4] + c[5];
        let _r = Math.floor(Number.parseInt(r, 16) * ratio);
        let _g = Math.floor(Number.parseInt(g, 16) * ratio);
        let _b = Math.floor(Number.parseInt(b, 16) * ratio);
        _r = _r < 255 ? _r : 255;
        _g = _g < 255 ? _g : 255;
        _b = _b < 255 ? _b : 255;
        const _fr = _r.toString(16);
        const _fg = _g.toString(16);
        const _fb = _b.toString(16);
        const res = `#${_fr}${_fg}${_fb}`;
        return res;
    },
};

const webgl = {
    ctx: '',
    strokeStyle: '',
    fillStyle: '',
    vw: 1,
    vh: 1,
    init: function (id) {
        var ctx;
        let result = { success: false, message: 'Init failed' };
        var canvas = document.getElementById(id);
        console.log('canvas = ', canvas);
        if (!canvas) {
            return result;
        }
        if (canvas.getContext) {
            ctx = canvas.getContext('2d');
            result.success = true;
            result.message = 'Init succes';
        } else {
            result.message = 'Opengl not supported';
        }
        this.ctx = ctx;
        return result;
    },
    update: function (h, w, objs, points = [[]], max = 0) {
        this.vw = w;
        this.vh = h;
        this.ctx.clearRect(0, 0, w, h);

        this.ctx.lineJoin = 'round';
        this.ctx.setLineDash([]);
        this.ctx.rect(0, 0, w, h);
        this.ctx.fillStyle = '#000';
        this.ctx.fill();

        for (let i = 0; i < objs.length; i++) {
            const obj = objs[i];
            const name = obj.name;
            const coord = name.split(':');
            const x = coord[0];
            const y = coord[1];
            if (x < points.length && y < points[0].length) {
                const val = points[x][y];
                obj.update(max, val);
            }
            obj.update_physics();
            obj.draw(this.ctx, w, h);
        }
        this.line(3, 'rgba(200, 200, 200, 0.3)', 0, h * 0.5, w, h * 0.5);
        this.line(3, 'rgba(200, 200, 200, 0.3)', 0, h * 0.167, w, h * 0.167);
        this.line(3, 'rgba(200, 200, 200, 0.3)', 0, h * 0.833, w, h * 0.833);
        this.line(3, 'rgba(200, 200, 200, 0.3)', w * 0.5, h * 0.167, w * 0.5, h * 0.833);
    },
    line: function (strokeWidth, strokeStyle, x0, y0, x1, y1) {
        this.ctx.lineWidth = strokeWidth;
        this.ctx.strokeStyle = strokeStyle;

        this.ctx.beginPath();
        this.ctx.moveTo(x0, y0);
        this.ctx.lineTo(x1, y1);
        this.ctx.stroke();
    },
    rect: function (strokeWidth, strokeStyle, fill, fillStyle, x, y, w, h) {
        this.ctx.strokeWidth = strokeWidth;
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.fillStyle = fillStyle;
        this.ctx.beginPath();
        this.ctx.rect(x, y, w, h);
        //this.ctx.stroke();
        if (fill) this.ctx.fill();
    },
    circle: function (strokeWidth, strokeStyle, fill, fillStyle, x, y, r) {
        this.ctx.strokeWidth = strokeWidth;
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.fillStyle = fillStyle;

        this.ctx.beginPath();
        this.ctx.arc(x, y, r, 0, Math.PI * 2, true);
        //this.ctx.stroke();
        if (fill) this.ctx.fill();
    },
    arc: function (strokeWidth, strokeStyle, fillStyle, x, y, r, a0, a1) {
        this.ctx.strokeWidth = strokeWidth;
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.fillStyle = fillStyle;
        this.ctx.beginPath();
        this.ctx.arc(x, y, r, a0, a1, true);
        this.ctx.stroke();
    },
};

const obj = {
    inited: false,
    props: {
        epochs: {
            moove: 0,
            last_update: 0,
            birth: 0,
        },
        visual: {
            strokeWidth: 0,
            strokeStyle: '#000',
            fillStyle: '#ff2277',
            shape: 'c0,0,1',
        },
        position: {
            x: 0.1, //relative to vw
            y: 0.1, //relative to vh
            vx: 0, //velocity
            vy: 0, //velocity
        },
        physics: {
            speed: 0,
            mass: 1,
            fw: 10, // grid width
            fh: 10, // grid height
            h: 10, // absolute
            w: 10, // absolute
        },
    },
    name: 'default',
    id: -1,
    vh: 1,
    vw: 1,
    init: function (id, name, width, height, fw, fh, x, y, shape, color) {
        if (this.inited) {
            console.log('Object already inited');
            return;
        }
        if (!id) {
            console.log('Object Id required');
            return;
        }
        console.log(id, name, height, width, x, y, shape, color);
        this.id = id;
        this.name = name || this.name;
        const p = JSON.stringify(this.props);
        this.props = JSON.parse(p);
        this.props.physics.fw = fw || this.fw;
        this.props.physics.fh = fh || this.fh;
        this.props.physics.h = height || this.h;
        this.props.physics.w = width || this.w;
        this.props.position.x = x;
        this.props.position.y = y;
        this.props.visual.shape = shape || 'c0,0,1';
        this.setFillColor(color);
        this.setStrokeColor(color);
        this.props.epochs['last_update'] = Date.now();
        this.inited = true;
    },
    update_physics: async function () {
        this.props.physics.h = this.vh / this.props.physics.fh;
        this.props.physics.w = this.vw / this.props.physics.fw;
        //this.updatePosition();
        //this.updateVelocity();
        this.props.epochs.last_update = Date.now();
    },
    update: async function (max, val) {
        const fill = util.thres_to_color(this.props.visual.strokeStyle, max, val);
        this.setFillColor(fill);
        this.props.epochs.last_update = Date.now();
    },
    draw: function (ctx, w, h) {
        this.vw = w;
        this.vh = h;
        const paths = this.props.visual.shape.split('\n');
        let fill = true;
        let strokeWidth = this.props.visual.strokeWidth;
        let strokeStyle = this.props.visual.strokeStyle;
        let fillStyle = this.props.visual.fillStyle;
        for (let i = 0; i < paths.length; i++) {
            const path = paths[i];
            const type = path[0];
            const res = path.replace(type, '');
            if (type == 's') {
                const s = res.split(',');
                strokeWidth = Number(s[0]);
                strokeStyle = s[1];
                fill = Boolean(s[2]);
                fillStyle = s[3];
            }
            if (type == 'l') {
                const points = res.split(' ').map((el) => el.split(',').map((e) => Number(e)));
                for (let n = 0; n < points.length - 1; n++) {
                    const p0 = points[n];
                    const p1 = points[n + 1];
                    const x0 = this.sVW(this.props.position.x) + this.sSW(p0[0]);
                    const y0 = this.sVH(this.props.position.y) + this.sSH(p0[1]);
                    const x1 = this.sVW(this.props.position.x) + this.sSW(p1[0]);
                    const y1 = this.sVH(this.props.position.y) + this.sSH(p1[1]);
                    webgl.line(strokeWidth, strokeStyle, x0, y0, x1, y1);
                }
            }
            if (type == 'r') {
                const r = res.split(',').map((e) => Number(e));
                const x = this.sVW(this.props.position.x); // + this.sSW(r[0] - r[2] / 2);
                const y = this.sVH(this.props.position.y); // + this.sSH(r[1] - r[3] / 2);
                const w = this.sSW(r[2]);
                const h = this.sSH(r[3]);
                webgl.rect(strokeWidth, strokeStyle, fill, fillStyle, x, y, w, h);
            }
            if (type == 'c') {
                const c = res.split(',').map((e) => Number(e));
                const x = this.sVW(this.props.position.x) + this.sSW(c[0]);
                const y = this.sVH(this.props.position.y) + this.sSH(c[1]);
                const r = this.sSW(c[2]);
                webgl.circle(strokeWidth, strokeStyle, fill, fillStyle, x, y, r);
            }
        }
    },
    sVH: function (val) {
        //scale viewport height
        return val * this.vh;
    },
    sVW: function (val) {
        //scale viewport width
        return val * this.vw;
    },
    sSH: function (val) {
        //scale self height
        return val * this.props.physics.h;
    },
    sSW: function (val) {
        //scale self width
        return val * this.props.physics.w;
    },
    hexThres(hex, thr) {
        const len = hex.length;
        const div = Math.pow(16, len) - 1;
        const n = Number.parseInt(hex, 16) / div;
        return n * thr;
    },

    setStrokeWidth: function (width) {
        if (!width) return;
        this.props.visual.strokeWidth = width;
    },
    setStrokeColor: function (color) {
        if (!color) return;
        this.props.visual.strokeStyle = color;
    },
    setFillColor: function (color) {
        if (!color) return;
        this.props.visual.fillStyle = color;
    },
};

export { webgl, obj };

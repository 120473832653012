<template>
    <v-container
        fluid
        class="ma-0 pa-0">
        <div
            class="canvas-c"
            id="canvas-container">
            <canvas
                class="canvas"
                id="canvas"
                :width="width"
                :height="height"
                @mousemove="mouse">
            </canvas>
            <div class="tools">
                <v-row class="ma-0 pa-0">
                    <div
                        class="text-center text-h6 ma-3"
                        style="width: 100%">
                        {{ status.toUpperCase() }}
                    </div>
                    <v-btn
                        @click="sendReq('start')"
                        color="success"
                        :disabled="status != 'idle'"
                        :loading="r_loading"
                        >START</v-btn
                    >
                    <v-btn
                        @click="sendReq('stop')"
                        color="red"
                        :disabled="status != 'running'"
                        :loading="r_loading"
                        >STOP</v-btn
                    >
                    <v-btn
                        @click="sendReq('reset')"
                        color="cyan"
                        :loading="r_loading"
                        >RESET</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="sendReq('exit')"
                        color="red"
                        :loading="r_loading"
                        >EXIT</v-btn
                    >
                    <!-- <div style="width: 100%">{{ req_res }}</div> -->
                </v-row>
                <v-row class="ma-0 pa-0">
                    <v-btn
                        @click="getImg('img')"
                        :loading="img_loading"
                        >Get Pic</v-btn
                    >
                    <!--  <v-btn
                        @click="getImg('homo_img')"
                        :loading="img_loading"
                        >Homography</v-btn
                    > -->

                    <v-img
                        :src="img_url"
                        max-height="calc(100vh - 250px)"></v-img>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
var ctx;
import axios from 'axios';
import { webgl, obj } from '@/utils';
const ip = window.localStorage.getItem('ip');
const port = window.localStorage.getItem('port');
if (!ip && port) {
    window.location.href = '/settings';
}
const api = `http://${ip}:${port}/`;
export default {
    name: 'Home',
    mounted() {
        webgl.init('canvas');
        window.addEventListener('resize', this.onResize);
        this.onResize();
        this.getUpdate();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            const el = document.getElementById('canvas-container');
            console.log(el);
            if (el) {
                const w = el.getBoundingClientRect().width;
                const h = el.getBoundingClientRect().height;
                if (w - 25 < h / 2) {
                    this.width = w - 25;
                    this.height = this.width * 2;
                } else {
                    this.height = h;
                    this.width = this.height / 2;
                }
                document.body.style.setProperty('--canva-width', `${this.width}px`);
            }
        },
        mouse(event) {
            this.x = event.offsetX;
            this.y = event.offsetY;
        },
        sendReq(ep) {
            const url = api + ep;
            this.r_loading = true;
            axios
                .get(url, { timeout: 10000 })
                .then((res) => {
                    console.log(res.data);
                    this.req_res = res.data;
                    this.r_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.r_loading = false;
                });
        },
        getImg(ep) {
            const url = api + ep;
            this.img_loading = true;
            axios
                .get(url, { responseType: 'blob' })
                .then((res) => {
                    console.log(res);
                    this.img_url = URL.createObjectURL(res.data);
                    this.img_loading = false;
                    setTimeout(() => {
                        this.getImg(ep);
                    }, 5000);
                })
                .catch((err) => {
                    console.log(err);
                    this.img_loading = false;
                });
        },
        async init(arr = []) {
            return new Promise((resolve, reject) => {
                if (arr.length == 0) {
                    reject('Array undefined');
                }
                if (arr[0].length == 0) {
                    reject('1D Array given');
                }
                const w = arr.length;
                const h = arr[0].length;
                const ow = this.width / w;
                const oh = this.height / h;
                let count = 1;
                for (let x = 0; x < w; x++) {
                    for (let y = 0; y < h; y++) {
                        const newObj = Object.assign({}, obj);
                        const color = y < h / 2 ? '#10ffff' : '#ff10ff';

                        newObj.init(count, `${x}:${y}`, ow, oh, w, h, x / w, y / h, `c0.5,0.5,0.5`, color);
                        this.objects.push(newObj);
                        count += 1;
                    }
                }
                resolve('done');
            });
        },
        async getUpdate() {
            const url = api + 'heatmap';
            axios
                .get(url)
                .then((res) => {
                    this.status = res.data.status;
                    if (!res.data.data || res.data.data.length == 0) {
                        setTimeout(() => {
                            this.getUpdate();
                        }, 10000);
                    } else if (this.points.length == 0) {
                        this.points = res.data.data;
                        this.init(res.data.data).then(() => {
                            setTimeout(() => {
                                this.getUpdate();
                            }, 5000);
                        });
                    } else {
                        this.points = res.data.data;
                        this.max = res.data.max;
                        this.status = res.data.status;
                        webgl.update(this.height, this.width, this.objects, this.points, this.max);

                        setTimeout(() => {
                            this.getUpdate();
                        }, 5000);
                    }
                })
                .catch((err) => {
                    setTimeout(() => {
                        this.getUpdate();
                    }, 10000);
                });
        },

        /* async draw() {
            webgl.update(this.height, this.width, this.objects, this.points, this.max);
            setTimeout(() => {
                this.draw();
            }, 100);
        }, */
        fill() {
            ctx.fill();
        },
    },
    data: () => ({
        p0: {},
        p1: {},
        points: [],
        max: 0,
        objects: [],
        x: -1,
        y: -1,
        oX: -1,
        oY: -1,
        snap: false,
        msg: '',
        width: 0,
        height: 0,
        s_width: 0,
        s_height: 0,
        strokeStyle: '#ff2277',
        fillStyle: '#112277',
        r_loading: false,
        img_loading: false,
        img_url: '',
        req_res: '',
        status: '',
    }),
};
</script>
<style scoped>
.canvas-c {
    border: black;
    border-style: solid;
    border-width: 1px;
    position: relative;
    margin: 25px;
    width: calc(100vw - 50px);
    height: calc(100vh - 120px);
}
.canvas {
    border: rgb(160, 160, 160);

    border-style: solid;
    border-width: 1px;
}
.tools {
    position: absolute;
    right: 0px;
    top: 0%;

    height: 100%;
    width: calc(100% - 25px - var(--canva-width));
}
</style>

<template>
    <v-container
        fluid
        class="ma-0 pa-0"
        style="position: relative">
        <div
            class="canvas-c"
            id="canvas-container"
            style="position: relative">
            <v-btn
                :loading="img_loading"
                fab
                absolute
                style="top: 5px"
                right
                @click="refreshImg()">
                <v-icon>mdi-refresh</v-icon></v-btn
            >
            <canvas
                class="canvas"
                id="canvas"
                :width="width"
                :height="height"
                @mousemove="mouse"
                @click="settle"></canvas>
        </div>
        <v-card
            class="tools pa-2"
            width="250">
            <v-select
                solo
                :label="img_loading ? 'Loading image...' : 'Load settings'"
                :items="settings_names"
                v-model="selected_sett"
                :loading="img_loading"
                no-data-text="No settings available to load"></v-select>
            <v-divider
                thickness="2"
                color="#000"></v-divider>
            <v-text-field
                solo
                hide-details
                class="mt-5"
                label="Cam ID"
                v-model="cam_id"></v-text-field>
            <v-btn
                class="my-3"
                block
                :color="length <= 0 || saving ? 'grey' : 'orange'"
                :disabled="length <= 0 || saving"
                @click="clear()"
                >Clear points</v-btn
            >
            <v-btn
                class="my-3"
                block
                color="primary"
                height="100"
                :loading="saving"
                :disabled="length < 4"
                @click="saveSettings()"
                >Save settings</v-btn
            >
            <v-divider
                class="mt-5"
                thickness="2"
                color="#000"></v-divider>
            <!--  <v-btn
                class="mb-3 mt-5"
                block
                :color="curr_sett_id < 0 ? 'grey' : 'red'"
                :loading="saving"
                :disabled="curr_sett_id < 0"
                @click="deleteSetting()"
                >Delete</v-btn 
            >-->
        </v-card>
        <v-dialog
            v-model="notify"
            hide-overlay
            width="450"
            persistent>
            <v-card
                color="primary"
                dark>
                <v-card-title>{{ notificationTitle }}</v-card-title>
                <v-card-text class="px-6 pt-2 text-start">{{ notificationText }} </v-card-text>
                <v-btn
                    text
                    block
                    @click="notify = false"
                    >ok</v-btn
                >
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="setupOvl"
            width="450"
            persistent>
            <v-card
                color="primary"
                dark>
                <v-card-title>Setup your connexion</v-card-title>
                <v-row class="ma-0 pa-0">
                    <v-col cols="8">
                        <span>IP</span>
                        <v-text-field
                            solo
                            v-model="ip"
                            prefix="http://"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <span>Port</span>
                        <v-text-field
                            solo
                            v-model="port"
                            prefix=":"></v-text-field>
                    </v-col>
                </v-row>
                <v-btn
                    text
                    block
                    @click="setup()"
                    :disabled="!(ip != '' && port != '')"
                    >Confirm</v-btn
                >
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { nextTick } from 'vue';
import axios from 'axios';
const secret = 'An1vgSDgv8uyRweetTR411bcbb7etyeRTTREDb354rreyt';
var ctx;
var background = new Image();

function search(sett, cam) {
    let id = -1;
    let n = -1;
    for (let i = 0; i < sett.length; i++) {
        if (sett[i].cam == cam) {
            id = sett[i].id;
            n = i;
            break;
        }
    }
    return [id, n];
}

async function uploadImg(url, data) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: url,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            data: data,
        })
            .then((res) => {
                resolve(res.data.data);
            })
            .catch((err) => {
                const result = JSON.stringify(err);
                const status = JSON.parse(result);
                console.log(status);
                reject(`Request status : ${status.status}`);
            });
    });
}

export default {
    name: 'Home',
    mounted() {
        var canvas = document.getElementById('canvas');
        window.addEventListener('resize', this.onResize);
        if (canvas.getContext) {
            this.msg = 'ok';
            ctx = canvas.getContext('2d');
        } else {
            this.msg = 'opengl not supported';
        }
        this.onResize();
        this.draw();
    },

    watch: {
        img_url(v) {
            let inst = this;
            inst.img_loaded = false;
            if (v != '') {
                inst.img_loading = true;
                background = new Image();
                background.src = this.img_url;
                background.onload = function () {
                    console.log('img loaded');
                    inst.img_loading = false;
                    inst.img_loaded = true;
                };
            }
        },
        setupOvl(v) {
            if (!v) {
                this.loadSettings();
            }
        },
        selected_sett(v) {
            if (v != -1) {
                const ip = window.localStorage.getItem('ip');
                const port = window.localStorage.getItem('port');
                if (!ip || !port) {
                    this.saving = false;
                    this.notify = true;
                    this.notificationTitle = `Unable to connect`;
                    this.notificationText = `IP and/or Port missing`;
                    return;
                }
                this.settings_list.forEach((el) => {
                    if (el.id == v) {
                        this.img_url = `http://${ip}:${port}${el.img}`;
                        this.cam_id = el.cam;
                        let _h = JSON.parse(el.homo);
                        let _n = JSON.parse(el.net);
                        this.points = [];
                        for (let i = 0; i < _h.length; i++) {
                            const e = _h[i];
                            this.pushPoint(e[0], e[1]);
                        }
                        for (let i = 0; i < _n.length; i++) {
                            const e = _n[i];
                            this.pushPoint(e[0], e[1]);
                        }
                        this.pushPoint(0, 0);
                    }
                });
            }
        },
        cam_id(v) {
            const curr_sett_sr = search(this.settings_list, v);
            this.curr_sett_id = curr_sett_sr[0];
            this.curr_sett_no = curr_sett_sr[1];
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize() {
            const el = document.getElementById('canvas-container');
            if (el) {
                const w = el.getBoundingClientRect().width;
                const h = el.getBoundingClientRect().height;
                if (w * 0.5625 < h) {
                    this.width = w;
                    this.height = this.width * 0.5625;
                } else {
                    this.height = h;
                    this.width = this.height * 1.7777777777;
                }
            }
        },
        mouse(event) {
            if (this.oX > -1 && this.oY > -1 && Math.abs(event.offsetX - this.oX * this.width) < 10 && Math.abs(event.offsetY - this.oY * this.height) < 10) {
                this.snap = true;
                this.x = this.oX * this.width;
                this.y = this.oY * this.height;
            } else {
                this.snap = false;
                this.x = event.offsetX;
                this.y = event.offsetY;
            }
            const l = this.points.length - 1;
            if (l < 0) {
                this.points.push({
                    x: this.x / this.width,
                    y: this.y / this.height,
                    settled: false,
                    end: false,
                });
                return;
            }
            if (!this.points[l].settled) {
                this.points[l].x = this.x / this.width;
                this.points[l].y = this.y / this.height;
            }
        },
        pushPoint(x, y) {
            this.length = this.points.length;
            if (this.length > 6) return;
            if (this.length > 0) this.points[this.length - 1].settled = true;
            this.points.push({
                x: x / 1920,
                y: y / 1080,
                settled: false,
                end: this.snap,
            });
        },
        settle(event) {
            this.length = this.points.length;
            // ! Points Number > 6 : Net enabled
            if (this.length > 4) return;
            this.points[this.length - 1].settled = true;
            this.points.push({
                x: this.x / this.width,
                y: this.y / this.height,
                settled: false,
                end: this.snap,
            });
            console.log(this.points);
        },
        draw() {
            ctx.clearRect(0, 0, this.width, this.height);

            if (this.img_loaded) {
                ctx.drawImage(background, 0, 0, this.width, this.height);
            }
            let oX = -1;
            let oY = -1;
            for (let i = 0; i < this.points.length; i++) {
                const p = this.points[i];
                if (Math.abs(p.x * this.width - this.x) < 10 && Math.abs(p.y * this.height - this.y) < 10 && p.settled) {
                    oX = p.x;
                    oY = p.y;
                }
                if (!p.end || !p.settled) this.circle(p.x, p.y, 4);

                if (i + 1 < this.points.length && i <= 2) {
                    const p1 = this.points[i + 1];
                    if (!p1.end) this.line(p.x, p.y, p1.x, p1.y);
                }
                if (i <= 3) {
                    this.text(p.x, p.y, `P${i + 1}`);
                }

                /*
                ? Deactivate Net 
                if (i == 5 || i == 4) {
                    const text = i == 4 ? 'Net Limit Down' : 'Net Limit Up';
                    this.text(p.x, p.y, text);
                    this.line(0, p.y, 1, p.y);
                } */
            }
            this.oX = oX;
            this.oY = oY;
            if (this.points.length >= 4) {
                const p0 = this.points[0];
                const p3 = this.points[3];
                this.line(p0.x, p0.y, p3.x, p3.y);
            }

            setTimeout(() => {
                this.draw();
            });
        },
        fill() {
            ctx.fill();
        },
        line(x0, y0, x1, y1) {
            ctx.strokeStyle = this.strokeStyle;
            ctx.fillStyle = this.fillStyle;
            ctx.beginPath();
            ctx.moveTo(x0 * this.width, y0 * this.height);
            ctx.lineTo(x1 * this.width, y1 * this.height);
            ctx.stroke();
        },
        rect(x0, y0, x1, y1) {
            ctx.strokeStyle = this.strokeStyle;
            ctx.fillStyle = this.fillStyle;
            const width = x1 - x0;
            const height = y1 - y0;
            ctx.beginPath();
            ctx.rect(x0, y0, width, height);
            ctx.stroke();
        },
        circle(x, y, r) {
            ctx.strokeStyle = this.strokeStyle;
            ctx.fillStyle = this.fillStyle;
            ctx.beginPath();
            ctx.arc(x * this.width, y * this.height, r, 0, Math.PI * 2, true);
            ctx.stroke();
        },
        arc(x, y, r, a0, a1) {
            ctx.strokeStyle = this.strokeStyle;
            ctx.fillStyle = this.fillStyle;
            ctx.beginPath();
            ctx.arc(x, y, r, a0, a1, true);
            ctx.stroke();
        },
        text(x, y, text) {
            ctx.font = '22px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(text, x * this.width, y * this.height - 10);
        },
        setStrokeColor(color) {
            this.strokeStyle = color;
        },
        setFillColor(color) {
            this.fillStyle = color;
        },

        fileinput() {
            document.getElementById('file_input').click();
        },
        clear() {
            this.points = [];
            this.length = 0;
        },
        refreshImg() {
            const ip = window.localStorage.getItem('ip');
            const port = window.localStorage.getItem('port');
            if (!ip || !port) {
                this.notify = true;
                this.notificationTitle = `Unable to connect`;
                this.notificationText = `IP and/or Port missing`;
                return;
            }
            const img_url = `http://${ip}:${port}/bkg`;
            this.getImg(img_url);
        },
        async getImg(url) {
            return new Promise((resolve, reject) => {
                this.img_loading = true;
                axios
                    .get(url, { responseType: 'blob' })
                    .then((res) => {
                        console.log(res);
                        this.img_url = URL.createObjectURL(res.data);
                        this.img_loading = false;
                        resolve('done');
                    })
                    .catch((err) => {
                        console.log(err);
                        this.img_loading = false;
                        reject(err);
                    });
            });
        },
        async loadSettings() {
            const ip = window.localStorage.getItem('ip');
            const port = window.localStorage.getItem('port');
            if (!ip || !port) {
                this.notify = true;
                this.notificationTitle = `Unable to connect`;
                this.notificationText = `IP and/or Port missing`;
                return;
            }
            this.r_loading = true;
            const img_url = `http://${ip}:${port}/bkg`;
            const url = `http://${ip}:${port}/cam_sett`;
            const getImg = await this.getImg(img_url);

            axios
                .get(url, { timeout: 10000 })
                .then((res) => {
                    console.log(res.data);
                    let _h = res.data;
                    this.points = [];
                    for (let i = 0; i < _h.length; i++) {
                        const e = _h[i];
                        this.pushPoint(e[0], e[1]);
                    }
                    this.pushPoint(0, 0);
                    this.r_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    const result = JSON.stringify(err);
                    const status = JSON.parse(result);
                    this.notify = true;
                    this.notificationTitle = `Something went wrong...`;
                    this.notificationText = `Loading settings request status : ${status.status}`;
                    this.r_loading = false;
                });
        },
        saveSettings() {
            this.saving = true;
            let homo = [];
            let net = [];
            for (let i = 0; i < this.points.length && i <= 5; i++) {
                const p = this.points[i];
                const x = p.x * 1920;
                const y = p.y * 1080;

                if (i <= 3) {
                    homo.push([Math.floor(x), Math.floor(y)]);
                } else {
                    net.push([Math.floor(x), Math.floor(y)]);
                }
            }
            const ip = window.localStorage.getItem('ip');
            const port = window.localStorage.getItem('port');
            if (!ip || !port) {
                this.saving = false;
                this.notify = true;
                this.notificationTitle = `Unable to connect`;
                this.notificationText = `IP and/or Port missing`;
                return;
            }
            const data = {
                homo: homo,
            };
            axios({
                method: 'post',
                url: `http://${ip}:${port}/set_cam_sett`,
                headers: {
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            })
                .then((res) => {
                    this.saving = false;
                    this.notify = true;
                    this.notificationTitle = `Settings saved`;
                    this.notificationText = `${res.data}`;
                    this.loadSettings();
                })
                .catch((err) => {
                    this.saving = false;
                    const result = JSON.stringify(err);
                    const status = JSON.parse(result);
                    this.notify = true;
                    this.notificationTitle = `Something went wrong...`;
                    this.notificationText = `Request status : ${status.status}`;
                });
        },
        deleteSetting() {
            const data = {
                cam: this.cam_id,
                secret: secret,
            };
            axios({
                method: 'delete',
                url: `http://${ip}:${port}/api/setcam`,
                headers: {
                    Accept: 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(data),
            })
                .then((res) => {
                    this.saving = false;
                    this.settings_list.splice(this.curr_sett_no, 1);
                    this.curr_sett_id = -1;
                    this.curr_sett_no = -1;
                    this.notify = true;
                    this.notificationTitle = `Setting deleted`;
                    this.notificationText = `${res.data.data}`;
                    this.loadSettings();
                })
                .catch((err) => {
                    this.saving = false;
                    const result = JSON.stringify(err);
                    const status = JSON.parse(result);
                    this.notify = true;
                    this.notificationTitle = `Something went wrong...`;
                    this.notificationText = `Request status : ${status.status}`;
                });
        },
        setup() {
            window.localStorage.setItem('ip', this.ip);
            window.localStorage.setItem('port', this.port);
            this.setupOvl = false;
        },
        async uploadImage(file) {
            this.uploadLoading = true;
            return new Promise((resolve, reject) => {
                if (!file) {
                    this.uploadLoading = false;
                    resolve('');
                } else if (file == []) {
                    this.uploadLoading = false;
                    resolve('');
                } else {
                    this.tob64().then((b64) => {
                        const ip = window.localStorage.getItem('ip');
                        const port = window.localStorage.getItem('port');
                        if (!ip || !port) {
                            reject('IP and/or Port missing');
                        }
                        let data = new FormData();
                        data.append('file', b64);
                        data.append('secret', secret);
                        const url = `http://${ip}:${port}/api/uploadimage`;
                        if (b64 != 'error') {
                            uploadImg(url, data)
                                .then((res) => {
                                    this.uploadLoading = false;
                                    this.img_url = res;
                                    resolve(res);
                                })
                                .catch((err) => {
                                    this.uploadLoading = false;
                                    this.notify = true;
                                    this.notificationTitle = `Something went wrong...`;
                                    this.notificationText = `${err}`;
                                    reject('Connexion Error');
                                });
                        } else {
                            this.uploadLoading = false;
                            reject('File reading error');
                        }
                    });
                }
            });
        },
        tob64() {
            var file = this.file[0];
            var reader = new FileReader();
            var result = '';
            reader.readAsDataURL(file);
            return new Promise((resolve, reject) => {
                reader.onload = () => {
                    result = reader.result;
                    resolve(result);
                };
                reader.onerror = function (error) {
                    //console.log(error);
                    resolve('error');
                };
            });
        },
    },
    data: () => ({
        p0: {},
        p1: {},
        points: [],
        x: -1,
        y: -1,
        oX: -1,
        oY: -1,
        snap: false,
        msg: '',
        width: 0,
        height: 0,
        length: 0,
        strokeStyle: '#00FF33',
        fillStyle: '#00FF33',
        settings_list: [],
        settings_names: [],
        selected_sett: '',
        cam_id: '',
        img_url: '',
        saving: false,
        curr_sett_id: -1,
        curr_sett_no: -1,
        r_loading: false,
        img_loaded: false,
        img_loading: false,
        uploadLoading: false,
        notify: false,
        notificationTitle: '',
        notificationText: '',
        ip: window.localStorage.getItem('ip') || '127.0.0.1',
        port: window.localStorage.getItem('port') || '5000',
        setupOvl: true,
        file: [],
    }),
};
</script>
<style scoped>
.canvas-c {
    margin: 25px;
    width: calc(100vw - 330px);
    height: calc(100vh - 100px);
}
.canvas {
    border: black;
    border-style: solid;
    border-width: 1px;
}
.tools {
    position: absolute;
    top: 0px;
    right: 15px;
}
</style>

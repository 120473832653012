<template>
  <v-container fluid class="ma-0 pa-0">
    <div class="canvas-c" id="canvas-container">
      <Plot
        :options="plotOptions"
        :datas="plotDatas"
        :frame="frame"
        :style="`max-width: ${width}px; max-height: ${height}px`"
      />
      <!--     <canvas
                class="canvas"
                id="canvas"
                :width="width"
                :height="height"
                @mousemove="mouse">
            </canvas> -->
      <div class="tools">
        <v-row class="ma-0 pa-0 row-centered">
          <div class="text-center text-h6 ma-3" style="width: 100%">
            {{
              status.toUpperCase() != "FAILED"
                ? status.toUpperCase()
                : "Server Down"
            }}
            - {{ isRecording ? "" : "Not" }} recording
          </div>
          <v-btn
            class="mx-2"
            @click="sendReq('start')"
            color="success"
            icon
            :disabled="status != 'idle'"
            :loading="r_loading || s_loading"
            ><v-icon x-large>mdi-play</v-icon></v-btn
          >
          <v-btn
            class="mx-2"
            @click="sendReq('stop')"
            color="red"
            icon
            :disabled="status != 'running'"
            :loading="r_loading || s_loading"
            ><v-icon x-large>mdi-stop</v-icon></v-btn
          >
          <v-btn
            class="mx-2"
            @click="sendReq(isRecording ? 'end_record' : 'record')"
            :disabled="status != 'running'"
            :loading="r_loading || s_loading"
            icon
            ><v-icon x-large :color="isRecording ? 'red' : 'white'"
              >mdi-record-rec</v-icon
            ></v-btn
          >
          <!--    <v-btn
                        @click="sendReq('end_record')"
                        color="yellow"
                        :disabled="status != 'running' || !isRecording"
                        :loading="r_loading || s_loading"
                        >END RECORD</v-btn
                    > -->
          <v-btn
            class="mx-2"
            @click="sendReq('reset')"
            color="cyan"
            icon
            :loading="r_loading || s_loading"
            ><v-icon>mdi-refresh</v-icon></v-btn
          >
          <v-select
            class="mx-2"
            solo
            rounded
            dense
            hide-details
            v-model="plot"
            :items="plotlist"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            @click="
              sendReq(
                status == 'idle' || status == 'running' ? 'exit' : 'launch'
              )
            "
            :color="status == 'idle' || status == 'running' ? 'red' : 'green'"
            :disabled="
              status != 'idle' &&
              status != 'running' &&
              status != 'exit' &&
              status != 'failed'
            "
            :loading="
              r_loading ||
              s_loading ||
              (status != 'idle' &&
                status != 'running' &&
                status != 'exit' &&
                status != 'failed')
            "
            >{{
              status == "idle" || status == "running" ? "EXIT" : "START"
            }}</v-btn
          >
          <!-- <div style="width: 100%">{{ req_res }}</div> -->
        </v-row>
        <v-row class="ma-0 pa-3">
          <v-btn
            :disabled="!showImg"
            @click="getImg('img')"
            :loading="img_loading"
            >Get Pic</v-btn
          >
          <v-btn
            @click="showImg = !showImg"
            :loading="showImg && img_loading"
            >{{ showImg ? "Show Video" : "Show Screenshot" }}</v-btn
          >
          <v-btn
            @click="sendReq('fetch_record')"
            color="blue"
            :loading="r_loading || s_loading"
            ><v-icon>mdi-download</v-icon> GET RECORD</v-btn
          >
          <!--  <v-btn
                        @click="getImg('homo_img')"
                        :loading="img_loading"
                        >Homography</v-btn
                    > -->

          <video
            controls
            v-if="!showImg"
            src="https://eu-central-1.linodeobjects.com/gamecam-bucket/padel--1112139168.mp4"
            style="width: 100%; max-height: calc(100vh - 250px)"
          ></video>
          <v-img
            v-if="showImg"
            :src="img_url"
            max-height="calc(100vh - 250px)"
          ></v-img>
        </v-row>
        <v-row style="position: absolute; bottom: 0px">
          <v-col cols="4">
            <div v-for="(el, k) in playerDists" :key="k">
              {{ k }} - {{ el }}m
            </div>
          </v-col>
          <v-col cols="4">
            <div v-for="(el, k) in playerDeltaDists" :key="k">
              {{ k }} - {{ el }}
            </div>
          </v-col>
          <v-col cols="4">
            <div v-for="(el, k) in playerPos" :key="k">{{ k }} - {{ el }}</div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import Plot from "@/components/Plot.vue";
var ctx;
import axios from "axios";
const ip = window.localStorage.getItem("ip");
const port = window.localStorage.getItem("port");
let lastDataFetch = Date.now();

console.log("protocol ==>", location.protocol);

const api = `${location.protocol}//${ip}:${port}/`;
export default {
  name: "Home",
  watch: {
    plot(v) {
      console.log("plot");
      this.plotDatas = this.storedDatas[v];
      this.frame++;
    },
  },
  beforeMount() {
    if (!ip || !port) {
      window.location.href = "/settings";
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.getStatus();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const el = document.getElementById("canvas-container");
      //console.log(el);
      if (el) {
        const w = el.getBoundingClientRect().width;
        const h = el.getBoundingClientRect().height;
        if (w - 25 < h / 2) {
          this.width = w - 25;
          this.height = this.width * 2;
        } else {
          this.height = h;
          this.width = this.height / 2;
        }
        document.body.style.setProperty("--canva-width", `${this.width}px`);
      }
    },
    mouse(event) {
      this.x = event.offsetX;
      this.y = event.offsetY;
    },
    sendReq(ep) {
      const url = api + ep;
      this.r_loading = true;
      this.s_loading = true;
      axios
        .get(url, { timeout: 10000 })
        .then((res) => {
          console.log(res.data);
          this.req_res = res.data;
          this.r_loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.r_loading = false;
        });
    },
    getImg(ep) {
      const url = api + ep;
      this.img_loading = true;
      axios
        .get(url, { responseType: "blob" })
        .then((res) => {
          console.log(res);
          this.img_url = URL.createObjectURL(res.data);
          this.img_loading = false;
          /* setTimeout(() => {
                        this.getImg(ep);
                    }, 15000); */
        })
        .catch((err) => {
          console.log(err);
          this.img_loading = false;
        });
    },
    async init(arr = []) {
      if (arr.length == 0) {
        return false;
      }
      if (arr[0].length == 0) {
        return true;
      }
      const w = arr.length;
      const h = arr[0].length;
      this.plotOptions.height = h;
      this.plotOptions.width = w;
      return false;
    },
    flatten(data) {
      if (!data || data.length == 0 || data[0].length == 0) return [];
      const res = [];
      const _x = data.length;
      const _y = data[0].length;

      for (let y = _y - 1; 0 <= y; y--) {
        for (let x = _x - 1; 0 <= x; x--) {
          res.push(data[x][y]);
        }
      }
      console.log(res.length);

      return res;
    },
    getPlayerDatas(data) {
      return;
      const _x = data.length;
      const _y = data[0].length;

      const p1 = [];
      const p2 = [];
      const p3 = [];
      const p4 = [];
      for (let y = _y - 1; 0 <= y; y--) {
        for (let x = _x - 1; 0 <= x; x--) {
          if (x < _x / 2 && y < _y / 2) {
            p2.push(data[x][y]);
          } else {
            const val = Math.floor(data[x][y] - this.max * 0.75);
            p2.push(val >= 0 ? val : 0);
          }
          if (x > _x / 2 && y < _y / 2) {
            p1.push(data[x][y]);
          } else {
            const val = Math.floor(data[x][y] - this.max * 0.75);
            p1.push(val >= 0 ? val : 0);
          }
          if (x > _x / 2 && y > _y / 2) {
            p4.push(data[x][y]);
          } else {
            const val = Math.floor(data[x][y] - this.max * 0.75);
            p4.push(val >= 0 ? val : 0);
          }
          if (x < _x / 2 && y > _y / 2) {
            p3.push(data[x][y]);
          } else {
            const val = Math.floor(data[x][y] - this.max * 0.75);
            p3.push(val >= 0 ? val : 0);
          }
        }
      }
      this.storedDatas["player1"] = p1;
      this.storedDatas["player2"] = p2;
      this.storedDatas["player3"] = p3;
      this.storedDatas["player4"] = p4;
      const bl = this.storedDatas["ball_landing"].length;
      const rn = Math.floor((Math.random() * 100000) % bl);
      const v = this.storedDatas["ball_landing"][rn];
      this.storedDatas["ball_landing"][rn] = v + 1;
      console.log("bl : ", bl, " rn : ", rn, " v : ", v);
      console.log(this.storedDatas);
    },
    async getStatus() {
      const url = api;
      try {
        axios
          .get(url, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
            timeout: 15000,
          })
          .then((res) => {
            this.s_loading = false;
            this.status = res.data;
            if (this.status == "idle" || this.status == "running") {
              this.getUpdate();
            }
          });
      } catch (e) {
        this.s_loading = false;
        console.error(e);
      } finally {
        setTimeout(() => {
          this.getStatus();
        }, 5000);
      }
    },
    async getUpdate() {
      if (Date.now() - lastDataFetch < 15000) {
        return;
      }
      lastDataFetch = Date.now();
      const url = api + "heatmap";
      axios
        .get(url, { timeout: 15000 })
        .then((res) => {
          //this.r_loading = false;
          this.status = res.data.status;
          this.isRecording = res.data.recording;
          if (!res.data.data || res.data.data.length == 0) {
            setTimeout(() => {
              this.getUpdate();
            }, 15000);
          } else if (this.plotDatas.length == 0) {
            console.log("init");
            this.plotDatas = this.flatten(res.data.data);
            this.storedDatas["ball_landing"] = this.flatten(res.data.data).map(
              (el) => el * 0
            );
            this.frame++;
            const init = this.init(res.data.data);
            if (!init) {
              this.plotDatas = [];
            }
            setTimeout(() => {
              this.getUpdate();
            }, 15000);
          } else {
            console.log("new data fetched");
            this.max = res.data.max;
            //this.getPlayerDatas(res.data.player_data);
            this.storedDatas["heatmap"] = this.flatten(res.data.data);
            this.storedDatas["ball_landing"] = this.flatten(res.data.ball_data);
            if ("0" in res.data.player_data) {
              this.storedDatas["player1"] = this.flatten(
                res.data.player_data["0"].heat_map
              );
              this.playerDists["player1"] = res.data.player_data["0"].dist;
              this.playerDeltaDists["player1"] =
                res.data.player_data["0"].delta_dist;
              this.playerPos["player1"] = JSON.stringify(
                res.data.player_data["0"].pos
              );
            }
            if ("1" in res.data.player_data) {
              this.storedDatas["player2"] = this.flatten(
                res.data.player_data["1"].heat_map
              );
              this.playerDists["player2"] = res.data.player_data["1"].dist;
              this.playerDeltaDists["player2"] =
                res.data.player_data["1"].delta_dist;
              this.playerPos["player2"] = JSON.stringify(
                res.data.player_data["1"].pos
              );
            }
            if ("2" in res.data.player_data) {
              this.storedDatas["player3"] = this.flatten(
                res.data.player_data["2"].heat_map
              );
              this.playerDists["player3"] = res.data.player_data["2"].dist;
              this.playerDeltaDists["player3"] =
                res.data.player_data["2"].delta_dist;
              this.playerPos["player3"] = JSON.stringify(
                res.data.player_data["2"].pos
              );
            }
            if ("3" in res.data.player_data) {
              this.storedDatas["player4"] = this.flatten(
                res.data.player_data["3"].heat_map
              );
              this.playerDists["player4"] = res.data.player_data["3"].dist;
              this.playerDeltaDists["player4"] =
                res.data.player_data["3"].delta_dist;
              this.playerPos["player4"] = JSON.stringify(
                res.data.player_data["3"].pos
              );
            }
            this.status = res.data.status;
            this.plotDatas = this.storedDatas[this.plot];
            console.log(this.storedDatas);
            this.frame++;
          }
        })
        .catch((err) => {
          //this.r_loading = true;
        });
    },

    fill() {
      ctx.fill();
    },
  },
  data: () => ({
    plotOptions: {
      width: 0,
      height: 0,
    },
    plotDatas: [],
    plot: "heatmap",
    plotlist: [
      {
        text: "heatmap",
        value: "heatmap",
      },
      {
        text: "Player 1",
        value: "player1",
      },
      {
        text: "Player 2",
        value: "player2",
      },
      {
        text: "Player 3",
        value: "player3",
      },
      {
        text: "Player 4",
        value: "player4",
      },
      {
        text: "Ball Landings",
        value: "ball_landing",
      },
    ],
    playerDists: {},
    playerDeltaDists: {},
    playerPos: {},
    storedDatas: {},
    frame: 0,
    p0: {},
    p1: {},
    max: 0,
    objects: [],
    x: -1,
    y: -1,
    oX: -1,
    oY: -1,
    snap: false,
    msg: "",
    width: 0,
    height: 0,
    s_width: 0,
    s_height: 0,
    strokeStyle: "#ff2277",
    fillStyle: "#112277",
    r_loading: false,
    s_loading: false,
    img_loading: false,
    img_url: "",
    showImg: true,
    req_res: "",
    status: "",
    isRecording: false,
  }),
  components: {
    Plot,
  },
};
</script>
<style scoped>
.canvas-c {
  border: black;
  border-style: solid;
  border-width: 1px;
  position: relative;
  margin: 25px;
  width: calc(100vw - 50px);
  height: calc(100vh - 120px);
}
.canvas {
  border: rgb(160, 160, 160);

  border-style: solid;
  border-width: 1px;
}
.tools {
  position: absolute;
  right: 0px;
  top: 0%;

  height: 100%;
  width: calc(100% - 25px - var(--canva-width));
}
</style>

<template>
    <div>
        <div
            ref="cvs"
            id="cvs"></div>
    </div>
</template>
<script>
import * as Plot from '@observablehq/plot';

export default {
    name: 'plot',
    watch: {
        frame(v, ov) {
            console.log('update : ', this.options);
            const p = Plot.plot({
                width: 2000,
                aspectRatio: 1,
                style: 'background-color: #121212; color: #121212;',
                marks: [
                    Plot.raster(this.datas, {
                        width: this.options.width,
                        height: this.options.height,
                    }),
                ],
            });
            const cvs = document.querySelector('#cvs');
            cvs.innerHTML = '';
            cvs.appendChild(p);
        },
    },
    data() {
        return {
            plot: '',
        };
    },

    props: ['options', 'datas', 'frame'],
};
</script>
